<template>
  <!--  BEGIN SIDEBAR  -->
  <div class="sidebar-wrapper sidebar-theme">
    <nav ref="menu" id="sidebar">
      <div class="shadow-bottom"></div>

      <perfect-scrollbar class="list-unstyled menu-categories" tag="ul" :options="{ wheelSpeed: 0.5, swipeEasing: !0, minScrollbarLength: 40, maxScrollbarLength: 300, suppressScrollX: true }">
        <li class="menu">
          <router-link to="/home" class="dropdown-toggle" @click="toggleMobileMenu">
            <div class="">
              <font-awesome-icon :icon="['fas', 'house']" />
              <span>{{ $t('home_sidebar') }}</span>
            </div>
          </router-link>
        </li>
        <li class="menu" v-if="userPermission?.appointment_system">
          <router-link to="/appointment" class="dropdown-toggle" @click="toggleMobileMenu">
            <div class="">
              <font-awesome-icon :icon="['fas', 'hospital-user']" />
              <span>{{ $t('appointment') }}</span>
            </div>
          </router-link>
        </li>
        <li class="menu" v-if="userPermission?.patientcase_system">
          <router-link to="/patientcase" class="dropdown-toggle" @click="toggleMobileMenu">
            <div class="">
              <font-awesome-icon :icon="['fas', 'user-doctor']" />
              <span>{{ $t('patientcase') }}</span>
            </div>
          </router-link>
        </li>
        <li class="menu" v-if="userPermission?.caseresult_system">
          <router-link to="/caseresult" class="dropdown-toggle" @click="toggleMobileMenu">
            <div class="">
              <font-awesome-icon :icon="['fas', 'house-medical-flag']" />
              <span>{{ $t('caseresult') }}</span>
            </div>
          </router-link>
        </li>
        <li class="menu" v-if="userPermission?.manage_report">
          <a
            class="dropdown-toggle menuCollapse"
            data-bs-toggle="collapse"
            data-bs-target="#report"
            aria-controls="report"
            aria-expanded="false"
          >
            <div class="">
              <font-awesome-icon :icon="['fas', 'chart-pie']" />
              <span>{{ $t('report') }}</span>
            </div>
            <div>
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-chevron-right"
              >
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
          </a>

          <ul id="report" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">
            <li><router-link to="/report/totalcase">สรุปผลจำนวนการวินิจฉัยตามหน่วยงาน</router-link></li>
            <li><router-link to="/report/rankingcase">จัดอันดับโรคที่วินิจฉัยทั้งหมด</router-link></li>
            <!--
            <li><a href="/report/totalcase" @click="manualRoute" class="">สรุปผลจำนวนการวินิจฉัยตามหน่วยงาน</a></li>
            <li><a href="/report/rankingcase" @click="manualRoute" class="">จัดอันดับโรคที่วินิจฉัยทั้งหมด</a></li>
            -->
          </ul>
        </li>
        <li class="menu" v-if="userPermission?.manage_member">
          <router-link to="/membermanagement" class="dropdown-toggle" @click="toggleMobileMenu">
            <div class="">
              <font-awesome-icon :icon="['far', 'id-card']" />
              <span>{{ $t('member') }}</span>
            </div>
          </router-link>
        </li>

        <li class="menu" v-if="userPermission?.manage_user">
          <router-link to="/adminmanagement" class="dropdown-toggle" @click="toggleMobileMenu">
            <div class="">
              <font-awesome-icon :icon="['fas', 'user-nurse']" />
              <span>{{ $t('admin') }}</span>
            </div>
          </router-link>
        </li>
          
        <li class="menu" v-if="userData?.admin_level===100 || userData?.admin_level===99">
          <a
              class="dropdown-toggle menuCollapse"
              data-bs-toggle="collapse"
              data-bs-target="#masterdata"
              aria-controls="masterdata"
              aria-expanded="false"
          >
            <div class="">
              <font-awesome-icon :icon="['fas', 'database']" />
              <span>{{ $t('master_data') }}</span>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-right"
              >
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
          </a>

          <ul id="masterdata" class="collapse submenu list-unstyled" data-bs-parent="#sidebar">
            <li><router-link to="/masterdata/hospital">{{ $t('organize') }}</router-link></li>
            <li><router-link to="/masterdata/reason">{{ $t('diagnose') }}</router-link></li>
            <li><router-link to="/masterdata/medicine">{{ $t('manage_medicine') }}</router-link></li>
            <li v-if="userData.admin_level===100"><router-link to="/masterdata/medbox">{{ $t('setting_medbox') }}</router-link></li>
          </ul>
        </li>
      </perfect-scrollbar>
    </nav>
  </div>
  <!--  END SIDEBAR  -->
</template>

<script setup>
    import { onMounted, ref, computed } from 'vue';
    import { useStore } from 'vuex';
    import moment from 'moment';
    import { useI18n } from 'vue-i18n';
    import { useRouter } from 'vue-router';
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter(); // Get the router instance

    const menu_collapse = ref('dashboard');
    let accountIDList = ref([]);
    let accountIDListHistory = ref([]);
    let isActive = ref(false);

    let duration = ref(null);
    let dayPassed = ref(null);

    let statusActive = ref(null);
    let statusActiveTxt = ref(null);

    let startChallenge = ref(null);
    let endChallenge = ref(null);

    const callApi = (param) => store.dispatch('api/apiCall', param);
    const userPermission = ref(store.getters['auth/getPermission']);
    const userData = ref(store.getters['auth/getMember']);

    let menuCollapseList = [];

    const memberID = computed(() => {
      const storedData = JSON.parse(localStorage.getItem('member'));
      return storedData ? storedData._id : null;
    });

    if (memberID.value) {
      const obj = {
        memberID: memberID.value
      };
      callApi({
        svName: 'memberService',
        fnName: 'accountList',
        param: obj
      }).then((res) => {
        if (res.status === 200) {
          let dataSet = res.data.result;
          setDataTrade(res.data.result);
          for (let i = 0;i < dataSet.length; i++) {
            if (dataSet[i]['text']  == '(Failed)' || dataSet[i]['text']  == '(Pass)') {
              accountIDListHistory.value.push(dataSet[i]);
            } else {
              accountIDList.value.push(dataSet[i]);
            }
          }
        }
      })
    }

    function setDataTrade (tradeAccount) {
        for (const data of tradeAccount) {
            statusActive.value = null;
            
            if (data.activateTime) {
                startChallenge.value = moment(data.activateTime).format('DD/MM/YYYY');

                if (data.rule.duration) {
                    duration.value = data.rule.duration;

                    if (duration.value) {
                        dayPassed.value = (moment(new Date())).diff(data.activateTime, 'days');
                        if (data.rule.checkSymbol.status == 3 || 
                            data.rule.dailyLoss.result.status == 3 ||
                            data.rule.maxLoss.result.status == 3) {
                            statusActive.value = 3; // แดง failed
                        }

                        if (data.rule.checkSymbol.status == 2 && data.rule.dailyLoss.result.status == 2 && 
                            data.rule.maxLoss.result.status == 2 && data.rule.minDay.result.status == 2 && 
                            data.rule.target.result.status == 2) 
                        {
                            statusActive.value = 2; // เขียว Pass
                        } 
                        else if (dayPassed.value > duration.value) 
                        {
                            statusActive.value = 3; // แดง failed
                        }

                        if (startChallenge.value) {
                            if (statusActive.value == 2) { // status 2 
                                statusActiveTxt.value = '(Pass)';
                            } else if (statusActive.value == 3) {
                                statusActiveTxt.value = '(Failed)';
                            } else {
                                statusActiveTxt.value = '(Active)';
                            }
                        }
                        data.text = statusActiveTxt.value;

                        
                    }
                }
            }
            if (data.accountID) {
                if (data.accountID == localStorage.getItem('accountID')) {
                    data.isActive = true;
                } else {
                    data.isActive = false;
                }
            }
        }
    }

    onMounted(() => {
      const selector = document.querySelector('#sidebar a[href="' + window.location.pathname + '"]');
      if (selector) {
        const ul = selector.closest('ul.collapse');
        if (ul) {
          let ele = ul.closest('li.menu').querySelectorAll('.dropdown-toggle');
          if (ele) {
            ele = ele[0];
            setTimeout(() => {
              ele.click();
            });
          }
        } else {
          selector.click();
        }
      }
    });

    const toggleMobileMenu = () => {
        hideCollapseActive();
        if (window.innerWidth < 991) {
            store.commit('toggleSideBar', !store.state.is_show_sidebar);
        }
    };

    function onClickRoute (accountData) {

        for (let i = 0 ; i < accountIDList.value.length; i++) {
            accountIDList.value[i].isActive = false;
        }

        for (let i = 0 ; i < accountIDListHistory.value.length; i++) {
            accountIDListHistory.value[i].isActive = false;
        }
        
        accountData.isActive = true;
        localStorage.setItem('accountID', accountData.accountID);
        const event = new CustomEvent('accountIDChanged');
        window.dispatchEvent(event);
    }

    const hideCollapseActive = () => {
      const itemList = document.querySelectorAll('a.menuCollapse');
      for(let item of itemList) {
        if (item.getAttribute('aria-expanded') === 'true') {
          item.click();
        }
        
      }
    }

    const manualRoute = (event) => {
      event.preventDefault();
      router.push(event.target.getAttribute('href'));
    }
</script>

<style>
  /*
  #sidebar ul.menu-categories ul.submenu > li a.active {
    color: unset;
  }
  */
</style>