<template>
  <!-- Modal -->
  <div class="modal fade" id="editProfileModal" tabindex="-1" aria-labelledby="profileModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable ">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="profileModalLabel">{{ $t('manage_account') }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="upload mt-4 pe-md-4 mb-4 text-center">
            <input 
              type="file" 
              class="d-none" 
              accept="image/*"
              @change="onFileChange($event)"
              ref="fileInput"
            >
            <img 
              :src="imgProfile" 
              alt="profile" 
              class="profile-preview"
              @click="triggerFileInput()"
            >
            <p class="mt-2 text-primary" @click="triggerFileInput()">{{ $t('upload_picture') }}</p>
          </div>

          <div class="row">
            <div class="mb-4 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label class="col-form-label" for="example_lable">{{ $t('name') }}</label>
              <div>
                <input type="text" id="example_lable" class="form-control" placeholder=" " v-model="userObj.admin_name"/>
              </div>
            </div>
            <div class="mb-4 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <label class="col-form-label" for="example_lable">{{ $t('surname') }}</label>
              <div>
                <input type="text" id="example_lable" class="form-control" placeholder=""  v-model="userObj.admin_surname"/>
              </div>
            </div>
          </div>

          <div class="mb-4">
            <label class="col-form-label" for="example_lable">{{ $t('citizen_id') }}</label>
            <div>
              <input type="text" id="example_lable" class="form-control" placeholder=""  v-model="userObj.admin_citizen_id"/>
            </div>
          </div>

          <div class="mb-4">
            <label class="col-form-label" for="example_lable">{{ $t('username') }}</label>
            <div>
              <input type="text" id="example_lable" class="form-control" placeholder=""  v-model="userObj.admin_username"/>
            </div>
          </div>

          <div class="mb-4">
            <label class="col-form-label" for="example_lable">{{ $t('email') }}</label>
            <div>
              <input type="text" id="example_lable" class="form-control" placeholder="" v-model="userObj.admin_email"/>
            </div>
          </div>

          <div class="checkbox-primary custom-control custom-checkbox text-color mb-4">
            <input
              type="checkbox"
              class="custom-control-input"
              id="changpass"
              v-model="isChangePass"
              @click="clickChangePassword(isChangePass)"
            />
            <label class="custom-control-label" for="changpass"> {{ $t('change_password') }} </label>
          </div>

          <div  v-show="isChangePass">
            <div class="mb-4">
              <label class="col-form-label" for="example_lable">{{ $t('password') }}</label>
              <div>
                <input type="password" id="example_lable" class="form-control" placeholder="" v-model="userObj.admin_password"/>
              </div>
            </div>

            <div class="mb-4">
              <label class="col-form-label" for="example_lable">{{ $t('confirm_password') }}</label>
              <div>
                <input type="password" id="example_lable" class="form-control" placeholder="" v-model="confirmPassword" />
              </div>
            </div>
          </div>
        </div>
        
        <div class="modal-footer">
          <button type="button" class="btn btn-success" @click="saveUser()"><font-awesome-icon class="me-2" :icon="['far', 'floppy-disk']" />{{ $t('save') }}</button>
        </div>
      </div>
    </div>
  </div>
<!-- End Modal -->
</template>
<script setup>
  // import zone
  import { onMounted, ref } from 'vue';
  import { useStore } from 'vuex';
  import { useI18n } from 'vue-i18n';

  // variable zone
  const store = useStore();
  const callApi = (param) => store.dispatch('api/apiCall', param);
  const { t } = useI18n();
  const userData = ref(store.getters['auth/getMember']);
  const editProfileModal = ref(null);
  const isChangePass = ref(false);
  const confirmPassword = ref('');
  const checkPass = ref(true);
  const emit = defineEmits(['callback']);
  const imgProfile = ref(null);
  const userObj = ref({
    _id: null,
    admin_name: '',
    admin_surname: '',
    admin_citizen_id: '',
    admin_username: '',
    admin_email: '',
    admin_password: '',
    file_id: null,
    last_file_id: null
  });

  const lastFileID = ref(null);
  // end valiable zone ======================================================================

  // function zone ==========================================================================
  const showModal = () => {
    if (!editProfileModal.value) {
      editProfileModal.value = new bootstrap.Modal(document.getElementById('editProfileModal'), {});
    }
    isChangePass.value = false;
    getUserByID();
    editProfileModal.value.show();
  };

  const hideModal = () => {
    if (editProfileModal.value) {
      editProfileModal.value.hide();
    }
  };

  // image zone
  const triggerFileInput = () => {
    document.querySelector('input[type="file"]').click();
  };

  const onFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // Display the selected image as a preview
      const reader = new FileReader();
      reader.onload = (e) => {
        imgProfile.value = e.target.result;
      };
      reader.readAsDataURL(file);

      // Upload the image to the server
      const resUpload = await uploadFile(file);
      if (resUpload) {
        userObj.value.file_id = resUpload[0];
        userObj.value.last_file_id = lastFileID.value;
      } else {
        alertWarningMsg(t('failed_upload_image'));
      }
    }
  };

  const uploadFile = async (file) => {
    try {
      const obj = {
        svName: 'uploadFileService',
        fnName: 'uploadImg',
        param: file
      };

      const upLoadImgFile = await callApi(obj);

      if (upLoadImgFile.status === 200) {
        return [upLoadImgFile.data.result.file_id];
      } else {
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };


  const getUserByID = async () => {
    try {
      const query = {
        _id: userData.value.admin_id
      }
      const res = await callApi({
        svName: 'adminService',
        fnName: 'getAdminByID',
        param: query
      });
      if (res.status == 200) {
        userObj.value = res.data.result[0];
        lastFileID.value = userObj.value.file_id;
        userObj.value._id = res.data.result[0].admin_id;
        if (userObj.value.file_name) {
          imgProfile.value = await getfileImg(userObj.value.file_name);
        } else {
          imgProfile.value = '/assets/images/transparent/profile.jpg';
        }
        
      }
    } catch (e) {
      console.log(e);
      alertWarningMsg(t('wrong'));
    }
  };

  const getfileImg = async (file_name) => {
    try {
      const getFile = await callApi({
        svName: 'uploadFileService',
        fnName: 'getFile',
        param: { fileName: file_name }
      });
      if (getFile.status === 200) {
        const base64Data = getFile.data.base64File;
        const fileName = file_name;
        let fileType = '';
        if (fileName.endsWith('.png')) {
          fileType = 'image/png';
        } else if (fileName.endsWith('.jpg') || fileName.endsWith('.jpeg')) {
          fileType = 'image/jpeg';
        }
        return `data:${fileType};base64,${base64Data}`;
      }
    } catch (error) {
      console.error('Error fetching file:', error);
      return null;
    }
  };

  const validateEmail = (email) => {
    try {
      const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return (pattern.test(email)) ? true : false;
    } catch (error) {
      return false;
    }
  };

  const validationUser = () => {
    if (userObj.value.admin_username.trim() === '' || userObj.value.admin_username === null) {
      alertWarningMsg(t('please_enter_username'));
      return false;
    }

    if (isChangePass.value) {
      if (!userObj.value.admin_password || !confirmPassword.value) {
        alertWarningMsg(!userObj.value.admin_password ? t('please_enter_password') : t('please_confirm_password'));
        return false;
      }

      if (userObj.value.admin_password !== confirmPassword.value) {
        checkPass.value = false;
        alertWarningMsg(t('please_match_password'));
        return false;
      }
    }

    if (userObj.value.admin_name === null || userObj.value.admin_name.trim() === '') {
      alertWarningMsg(t('please_enter_name'));
      return false;
    }

    if (userObj.value.admin_surname === null || userObj.value.admin_surname.trim() === '') {
      alertWarningMsg(t('please_enter_surname'));
      return false;
    }

    if (userObj.value.admin_citizen_id.trim() === '') {
      alertWarningMsg(t('please_enter_citizenid'));
      return false;
    }
    
    userObj.value.admin_citizen_id = userObj.value.admin_citizen_id.replace(/-/g, '');
    const regex = /^\d{13}$/;
    if (!regex.test(userObj.value.admin_citizen_id.trim())) {
      alertWarningMsg(t('please_enter_citizenid_correct'));
      return false;
    }

    if (userObj.value.admin_email === null || userObj.value.admin_email.trim() === '') {
      alertWarningMsg('กรุณาระบุอีเมล');
      return false;
    }

    if (userObj.value.admin_email) {
      if (!validateEmail(userObj.value.admin_email)) {
        alertWarningMsg('รูปแบบอีเมลไม่ถูกต้อง');
        return false;
      } else {
        userObj.value.admin_email = userObj.value.admin_email.toLowerCase();
      }
    }
    return true;
  };

  const saveUser = async () => {
    try {
      if (validationUser()) {
        let dataSave = userObj.value;
        const confirm = await new window.Swal({
          icon: 'warning',
          title: t('warning'),
          text: t('confirm_save'),
          showCancelButton: true,
          confirmButtonText: t('confirm'),
          cancelButtonText: t('cancel'),
          reverseButtons: true
        });
        if (confirm.isConfirmed) {
          const res = await callApi({
            svName: 'adminService',
            fnName: 'updateAdmin',
            param: dataSave
          });
          if (res.status == 200) {
            const confirm2 = await new window.Swal({
              icon: 'success',
              title: t('warning'),
              text: t('save_success'),
              showCancelButton: false,
              confirmButtonText: t('confirm'),
              reverseButtons: true
            });
            if (confirm2.isConfirmed) {
              hideModal();
              emit('callback');
            }
          } else {
            alertWarningMsg(t('failed_save_data'));
          }
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        await new window.Swal({
          icon: 'error',
          title: t('warning'),
          text: error.response.data.message,
          confirmButtonText: t('confirm'),
        });
      } else {
        alertWarningMsg(t('wrong'));
      }
    }
  };

  const clickChangePassword = (current) => {
    userObj.value.admin_password = '';
    confirmPassword.value = '';
    isChangePass.value = !current;
  }

  const alertWarningMsg = (txt) => {
    try {
      new window.Swal({
          icon: 'warning',
          title: 'แจ้งเตือน',
          text: txt,
          confirmButtonText: 'ตกลง'
      });
    } catch (error) {
        console.log(error);
    }
  };
  // end function zone ======================================================================

  onMounted(() => {
    editProfileModal.value = new bootstrap.Modal(document.getElementById('editProfileModal'), {});
  });

  defineExpose({
    showModal,
    hideModal,
    editProfileModal
  });
</script>