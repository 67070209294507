<template>
  <div>
    <!--  BEGIN NAVBAR  -->
    <div class="header-container  fixed-top">
      <header class="header  navbar navbar-expand-sm">
        <ul class="navbar-item theme-brand flex-row text-center">
          <li class="nav-item theme-logo">
            <router-link to="/home">
              <img src="@/assets/images/navbar/headlogo.svg" class="navbar-logo" alt="logo" />
            </router-link>
          </li>
          <li class="nav-item theme-text"><a href="/home" class="router-link-active active nav-link" aria-current="page">
              TELEMED </a></li>
        </ul>
        <div class="navbar-item flex-row ms-md-auto">
          <div class="dropdown nav-item user-profile-dropdown btn-group mx-1 hoverUp hovLg">
            <a href="javascript:;" id="ddluser" data-bs-toggle="dropdown" aria-expanded="false"
              class="btn dropdown-toggle btn-icon-only user nav-link">
              <img :src="require(`@/assets/images/flag/${selectedLang.code}.png`)" class="rounded-circle">
            </a>
            <ul class="dropdown-menu dropdown-menu-right flag_dropdown" aria-labelledby="ddluser">
              <li role="presentation" v-for="item in countryList" :key="item.code" @click.prevent="changeLanguage(item)">
                <a href="javascript:;" class="dropdown-item">
                  <div class="rectangleImg"><img :src="require(`@/assets/images/flag/${item.code}.png`)"></div> {{
                    item.name }}
                </a>
              </li>
            </ul>
          </div>
          <div class="dropdown nav-item user-profile-dropdown btn-group mx-1 hoverUp hovLg" v-show="isMember">
            <router-link to="/profile" class="btn dropdown-toggle btn-icon-only user nav-link">
              <img src="../../assets/images/navbar/profileIcon.png" class="rounded-circle">
            </router-link>
          </div>
          <div class="dropdown nav-item user-profile-dropdown btn-group mx-1 hoverUp hovLg">
            <a href="javascript:;" id="ddluser" data-bs-toggle="dropdown" aria-expanded="false"
              class="btn dropdown-toggle btn-icon-only user nav-link">
              <!-- <img src="../../assets/images/navbar/profileIcon.png" class="rounded-circle"> -->
              <img 
                :src="imgProfile" 
                alt="profile" 
                class="rounded-circle"
              >
            </a>
            <ul class="dropdown-menu dropdown-menu-right" aria-labelledby="ddluser">
              <li role="presentation">
                <router-link to="" class="dropdown-item" @click="showProfileModal">
                  <font-awesome-icon :icon="['far', 'user']" /> {{ $t('manage_account') }}
                </router-link>
              </li>
              <li class="borderTop" role="presentation">
                <router-link
                  to=""
                  @click="signOut()"
                  class="dropdown-item"
                >
                  <font-awesome-icon :icon="['fas', 'arrow-right-from-bracket']" /> {{ $t('logout') }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </div>
    <!--  BEGIN NAVBAR  -->
    <div class="sub-header-container">
      <header class="header navbar navbar-expand-sm">
        <a href="javascript:void(0);" class="sidebarCollapse" data-placement="bottom"
          @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-menu">
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
          </svg>
        </a>

        <!-- Portal vue/Teleport for Breadcrumb -->
        <!-- <div id="breadcrumb" class="vue-portal-target">รพสต. กัสแอเรีย</div> -->
        <div id="breadcrumb" class="vue-portal-target">{{ organizeName }}</div>
      </header>
    </div>
    <!--  END NAVBAR  -->

  </div>

  <profile-modal ref="profileModalRef" @callback="getUserByID()"></profile-modal>
</template>

<script setup>
  import { onMounted, ref, computed, watch } from 'vue';
  import { useI18n } from 'vue-i18n';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import ProfileModal from '@/components/modal/telemed/profileModal.vue';

  
  const { t } = useI18n();
  const store = useStore();
  const callApi = (param) => store.dispatch('api/apiCall', param);
  const selectedLang = ref({
    code: 'th',
    name: 'name'
  });
  const countryList = ref(store.state.countryList);
  const i18n = useI18n();

  const isMember = ref(false);
  const profileModalRef = ref(null);

  const userData = ref(store.getters['auth/getMember']);
  const organizeName = ref('');
  const imgProfile = ref(null);

  // function zone =============================================================================
  const getUserByID = async () => {
    try {
      const query = {
        _id: userData.value.admin_id
      };
      const res = await callApi({
        svName: 'adminService',
        fnName: 'getAdminByID',
        param: query
      });
      if (res.status === 200) {
        const fileName = res.data.result[0]?.file_name || null;
        if (fileName) {
          imgProfile.value = await getfileImg(fileName);
        } else {
          imgProfile.value = '/assets/images/transparent/profile.jpg';
        }
      } else {
        imgProfile.value = '/assets/images/transparent/profile.jpg';
      }
    } catch (e) {
      console.error(e);
      alertWarningMsg(t('wrong'));
      imgProfile.value = '/assets/images/transparent/profile.jpg';
    }
  };


  const getfileImg = async (file_name) => {
    try {
      const getFile = await callApi({
        svName: 'uploadFileService',
        fnName: 'getFile',
        param: { fileName: file_name }
      });
      if (getFile.status === 200) {
        const base64Data = getFile.data.base64File;
        const fileName = file_name;
        let fileType = '';
        if (fileName.endsWith('.png')) {
          fileType = 'image/png';
        } else if (fileName.endsWith('.jpg') || fileName.endsWith('.jpeg')) {
          fileType = 'image/jpeg';
        }
        return `data:${fileType};base64,${base64Data}`;
      }
    } catch (error) {
      console.error('Error fetching file:', error);
      return null;
    }
  };

  const toggleMode = (mode) => {
    let currentTheme = 'light';
    if (mode) {
      currentTheme = mode;
      localStorage.setItem('mode', currentTheme);
      window.$appSetting.toggleMode(currentTheme);
    } else {
      currentTheme = (localStorage.getItem('mode')) ? localStorage.getItem('mode') : 'light'
    }

    window.$appSetting.toggleMode(currentTheme);
  };

  const changeLanguage = (item) => {
    selectedLang.value = item;
    window.$appSetting.toggleLanguage(item);

    i18n.locale.value = item.code;
  };

  const alertWarningMsg = (txt) => {
    try {
      new window.Swal({
        icon: 'warning',
        title: t('txt_alert'),
        text: txt,
        confirmButtonText: t('submit')
      });
    } catch (error) {
      console.log(error);
    }
  };

  const showProfileModal = () => {
    profileModalRef.value.showModal();
  }



  const signOut = () => {
    // callLogout();
    localStorage.removeItem('member');
    localStorage.removeItem('token');
    location.reload();
  }
  // end function zone

  onMounted(() => {
    selectedLang.value = window.$appSetting.toggleLanguage();
    toggleMode();
    organizeName.value = userData.value?.organize_name;
    if (userData.value) {
      getUserByID();
    }
    
  });
</script>
