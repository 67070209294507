import { createRouter, createWebHistory } from 'vue-router';

import store from '../store';

// Vue.use(VueRouter);
// import { createApp } from 'vue';
// const app = createApp();
// app.use(createRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue'),
    meta: { layout: 'auth', sidebar: false, footer: false }
  },
  {
    path: '/forgotpassword/:token',
    name: 'forgotpassword',
    props: true,
    component: () => import('../views/forgotpassword.vue'),
    meta: { layout: 'auth', sidebar: false, footer: false }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home.vue'),
    meta: { layout: 'app', sidebar: true, footer: false }
  },
  {
    path: '/appointment',
    name: 'appointment',
    component: () => import('../views/appointment.vue'),
    meta: { layout: 'app', sidebar: true, footer: false, permission: 'appointment_system' }
  },
  {
    path: '/patientcase',
    name: 'patientcase',
    component: () => import('../views/patientcase.vue'),
    meta: { layout: 'app', sidebar: true, footer: false, permission: 'patientcase_system' }
  },
  {
    path: '/caseresult',
    name: 'caseresult',
    component: () => import('../views/caseresult.vue'),
    meta: { layout: 'app', sidebar: true, footer: false, permission: 'caseresult_system' }
  },
  {
    path: '/report',
    name: 'report',
    meta: { layout: 'app', sidebar: true, footer: false, permission: 'manage_report' },
    children: [
      {
        path: 'rankingcase',
        name: '/report/rankingcase',
        component: () => import('../views/report/rankingcase.vue'),
        meta: { layout: 'app', sidebar: true, footer: false, permission: 'manage_report' }
      },
      {
        path: 'totalcase',
        name: '/report/totalcase',
        component: () => import('../views/report/totalcase.vue'),
        meta: { layout: 'app', sidebar: true, footer: false, permission: 'manage_report' }
      }
    ]
  },
  {
    path: '/membermanagement',
    name: 'membermanagement',
    component: () => import('../views/membermanagement.vue'),
    meta: { layout: 'app', sidebar: true, footer: false, permission: 'manage_member' }
  },
  {
    path: '/adminmanagement',
    name: 'adminmanagement',
    component: () => import('../views/adminmanagement.vue'),
    meta: { layout: 'app', sidebar: true, footer: false, permission: 'manage_user' }
  },
  {
    path: '/patientresult/:mode/:id',
    name: 'patientresult',
    component: () => import('../views/patientresult.vue'),
    props: true,
    meta: { layout: 'app', sidebar: true, footer: false, permission: 'caseresult_system' }
  },
  {
    path: '/masterdata',
    name: 'masterdata',
    meta: { layout: 'app', sidebar: true, footer: false, permission: 'manage_master' },
    children: [
      {
        path: 'medicine',
        name: '/masterdata/medicine',
        component: () => import('../views/masterdata/medicine.vue'),
        meta: { layout: 'app', sidebar: true, footer: false, permission: 'manage_master' }
      },
      {
        path: 'hospital',
        name: '/masterdata/hospital',
        component: () => import('../views/masterdata/hospital.vue'),
        meta: { layout: 'app', sidebar: true, footer: false,  permission: 'manage_master' }
      },
      {
        path: 'reason',
        name: '/masterdata/reason',
        component: () => import('../views/masterdata/reason.vue'),
        meta: { layout: 'app', sidebar: true, footer: false,  permission: 'manage_master' }
      },
      {
        path: 'medbox',
        name: '/masterdata/medbox',
        component: () => import('../views/masterdata/medbox.vue'),
        meta: { layout: 'app', sidebar: true, footer: false,  permission: 'manage_master' }
      },
      {
        path: 'medicineunit',
        name: '/masterdata/medicineunit',
        component: () => import('../views/masterdata/medicineunit.vue'),
        meta: { layout: 'app', sidebar: true, footer: false,  permission: 'manage_master' }
      }
    ]
  },
  {
    path: '/housevisitnote',
    name: 'housevisitnote',
    component: () => import('../views/housevisitnote.vue'),
    meta: { layout: 'app', sidebar: true, footer: false }
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/test.vue'),
    meta: { layout: 'app', sidebar: true, footer: false }
  }
];

const router = new createRouter({
  // mode: 'history',
  history: createWebHistory(),
  linkExactActiveClass: 'active',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  const publicPages = ['login', 'forgotpassword', 'totalcase'];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = localStorage.getItem('member');
  const token = localStorage.getItem('token');

  const userData = JSON.parse(loggedIn);
  const userPermission = userData?.admin_permission;
  const { permission } = to.meta;

  if (authRequired && !loggedIn && !token) {
    return next('/login');
  }

  if (permission && !userPermission[permission]) { // check permission from meta.permission
    return next('/home');
  } else {
    // admin_level 100 = hignsuperadmin
    // admin_level 99 = superadmin

    // master data จัดการประเป๋าแพทย์ต้องเป็น admin_level 100 เท่านั้นถึงจะเข้าถึงได้
    if (userData?.admin_level !== 100 && to.name === '/masterdata/medbox') {
      return next('/home');
    }

    // master data จัดการหน่วยงาน admin_level 100 และ 99  เท่านั้นถึงจะเข้าถึงได้
    if (userData?.admin_level !== 100 && userData?.admin_level !== 99 && to.name === '/masterdata/hospital') {
      return next('/home');
    }
  }

  if (to.path === '/') {
    return next('/home');
  }

  if (to.meta && to.meta.layout && to.meta.layout === 'auth') {
    store.commit('setLayout', 'auth');
  } else {
    store.commit('setLayout', 'app');
  }

  next();
});

export default router;
